import { Canvas, useFrame } from "@react-three/fiber";
import { useSpring, a } from "@react-spring/three";
import { useDrag } from "@use-gesture/react";
import { Html, Center, useTexture, Edges } from "@react-three/drei";
import { useState, useCallback } from "react";
import "./style.scss";
import { useRef } from "react";
import config from "../../config";

const data = [
  `*Cut-Shib: The total amount of Cut is 1248163264128, 100% circulated, never to be reissued. All tokens have entered the liquidity pool and are permanently locked. Contract permissions have been discarded. Anyone who wants to get tokens needs to buy them from the market at the market price, meaning it's fair to anyone. This step has been completed.`,
  `*Cut-NFT: Introduce NFT to increase the use-case of Cut token and add a deflation destruction mechanism.`,
  `*Cut-XEN: Any wallet having the NFT can yield CUC token (with a fixed total amount) for a gas fee only, which will be extended to eth, bsc, and other networks.`,
  `*Cut-Staking: Pledge NFT, CUC is the reward token.`,
  `*Cut-UNI: Swap will also be an important part of the Ctrl-X ecosystem and be expanded to multiple networks.`,
  `*Cut-L2: L2 will be built according to Ctrl-X development needs. Since the time still remains before the halving of output of btc, eth, etc., there is enough time for preparation.`,
  `*Cut-Gamefi and Meta: Migrate to L2 network. Circulation tokens are CUT, CUC.`,
  `Be listed on global mainstream exchanges`,
];

const RoadPage = ({ index }) => {
  return (
    <div className="road">
      <div className="title">{index}</div>
      <div className="content">{data[index]}</div>
    </div>
  );
};

const RoadHtml = ({ index, position, rotation }) => {
  return (
    <Html
      occlude
      as="div"
      wrapperClass="html"
      //   fullscreen
      position={position}
      rotation={rotation}
      transform
      //   center
      distanceFactor={3}
      style={{
        userSelect: "none",
        pointerEvents: "none",
      }}
    >
      <RoadPage index={index} />
    </Html>
  );
};

const Roadmap = ({ angle, currentAngle, direct }) => {
  const texture = useTexture({
    map1: config.images.roadmap.map1,
    map2: config.images.roadmap.map2,
    map3: config.images.roadmap.map3,
    map4: config.images.roadmap.map4,
    map5: config.images.roadmap.map5,
    map6: config.images.roadmap.map6,
    map7: config.images.roadmap.map7,
    map8: config.images.roadmap.map8,
  });

  const current = useCallback(
    (index = 0) => {
      const angle = box_ref.current?.rotation.y
        ? (box_ref.current?.rotation.y / Math.PI) * 180
        : 0;
      return (
        (parseInt(
          (Math.abs(currentAngle - 360 * 10000 + angle) - index * 90 + 90) / 360
        ) *
          4 +
          index) %
        data.length
      );
    },
    [currentAngle]
  );

  const box_ref = useRef();

  useFrame((_, time) => {
    box_ref.current.rotation.y += time * 0.05 * direct;
  });

  return (
    <>
      <Center>
        <mesh ref={box_ref}>
          <a.mesh rotation-y={angle}>
            <boxGeometry args={[2, 2, 2]} />
            <meshBasicMaterial visible={true} opacity={0.0} transparent />
            <Edges scale={0.99}>
              <meshBasicMaterial transparent color="white" depthTest={false} />
            </Edges>
            <group>
              <mesh position={[0, 0, 1.001]}>
                <planeGeometry args={[2, 2]} />
                <meshBasicMaterial
                  map={texture[`map${current(0) + 1}`]}
                  transparent
                />
              </mesh>
              <mesh position={[1.001, 0, 0]} rotation={[0, Math.PI / 2, 0]}>
                <planeGeometry args={[2, 2]} />
                <meshBasicMaterial
                  map={texture[`map${current(1) + 1}`]}
                  transparent
                />
              </mesh>
              <mesh position={[0, 0, -1.001]} rotation={[0, -Math.PI, 0]}>
                <planeGeometry args={[2, 2]} />
                <meshBasicMaterial
                  map={texture[`map${current(2) + 1}`]}
                  transparent
                />
              </mesh>
              <mesh position={[-1.001, 0, 0]} rotation={[0, -Math.PI / 2, 0]}>
                <planeGeometry args={[2, 2]} />
                <meshBasicMaterial
                  map={texture[`map${current(3) + 1}`]}
                  transparent
                />
              </mesh>
            </group>
          </a.mesh>
        </mesh>
      </Center>
    </>
  );
};

const RoadmapBox = () => {
  const [{ angle }, api] = useSpring(() => ({ angle: 0 }));
  const [currentAngle, setCurrentAngle] = useState(0);
  const [direct, setDirect] = useState(-1);
  const bind = useDrag(({ offset, direction }) => {
    if (direction[0] !== 0) {
      setDirect(direction[0]);
    }

    api.start(() => {
      const _angle = offset[0] * 0.005;
      setCurrentAngle((_angle / Math.PI) * 180);
      return { angle: _angle };
    });
  });

  return (
    <Canvas {...bind()} camera={{ fov: 40 }} style={{ touchAction: "none" }}>
      <Roadmap angle={angle} currentAngle={currentAngle} direct={direct} />
    </Canvas>
  );
};

export default RoadmapBox;
