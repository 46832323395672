import { Canvas, extend, useFrame } from '@react-three/fiber'
import { shaderMaterial } from '@react-three/drei'
import { useRef } from 'react'
import * as THREE from 'three'
import useMouse from '../../hooks/useMouse'

const WaveMaterial = shaderMaterial({
    time: 0,
    color: new THREE.Color()
},
    `
    #define M_PI 3.14159265358979323846

    uniform float time;
    varying vec2 vUv;
    varying float vDepth;

    float rand(vec2 co){return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);}
    float rand (vec2 co, float l) {return rand(vec2(rand(co), l));}
    float rand (vec2 co, float l, float t) {return rand(vec2(rand(co, l), t));}

    float perlin(vec2 p, float dim, float time) {
    vec2 pos = floor(p * dim);
    vec2 posx = pos + vec2(1.0, 0.0);
    vec2 posy = pos + vec2(0.0, 1.0);
    vec2 posxy = pos + vec2(1.0);
    
    float c = rand(pos, dim, time);
    float cx = rand(posx, dim, time);
    float cy = rand(posy, dim, time);
    float cxy = rand(posxy, dim, time);
    
    vec2 d = fract(p * dim);
    d = -0.5 * cos(d * M_PI) + 0.5;
    
    float ccx = mix(c, cx, d.x);
    float cycxy = mix(cy, cxy, d.x);
    float center = mix(ccx, cycxy, d.y);
    
    return center * 2.0 - 1.0;
    }

    void main() {
        vUv = uv;
        float big = perlin(uv.xy + vec2(time, time) * 0.2, 2.0, 1.0);
        float middle = perlin(uv.xy + vec2(time, 0.0) * 0.1, 6.0, 3.0);
        float small = perlin(uv.xy + vec2(time, 0.0) * 0.02, 9.0, 5.0);
        float c = big * 0.3 + middle * 0.2 + small * 0.1;
        c = 2.0 - abs(c * 2.0 - 1.0) * 2.; 
        vec3 p = vec3(position.x, position.y, c);
        gl_Position = projectionMatrix * modelViewMatrix * vec4(p, 1.0);
        vec4 mvPosition = modelViewMatrix * vec4(p, 1.0);
        gl_PointSize = 30.0 * (1.0 / -mvPosition.z ) * (p.z + .5)  ;
        vDepth = p.z;
    }
`, `
    uniform vec3 color;
    varying float vDepth;
    void main() {
        if(length(gl_PointCoord - vec2(0.5)) < 0.5) {
            gl_FragColor = vec4(color * (vDepth + .5), 1.0);
        } else {
            gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
        }
    } 
`, (material) => {
    material.transparent = true

})

extend({ WaveMaterial })

const Wave = () => {
    return (
        <Canvas>
            <color attach='background' args={['black']} />
            <WaveMesh />
        </Canvas>
    )
}

const WaveMesh = () => {

    const mouse = useMouse()

    const material_ref = useRef()


    useFrame(({ clock, scene, camera }) => {
        material_ref.current.time = clock.elapsedTime
        camera.position.x += (mouse.x * .001 - camera.position.x) * .05
        camera.position.y += (-mouse.y * .005 - camera.position.y) * .05
        camera.position.z = 10

        camera.lookAt(scene.position)
    })

    return <points rotation-x={-1}>
        <planeGeometry args={[50, 50, 60, 60]} />
        <waveMaterial ref={material_ref} />
    </points>
}

export default Wave