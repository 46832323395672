import { useThree, extend, useFrame, Canvas } from "@react-three/fiber";
import { shaderMaterial } from "@react-three/drei";
import { useRef } from "react";

const UniverseMaterial = shaderMaterial(
  {
    iGlobalTime: 0,
    iResolution: { x: 0, y: 0 },
    iMouse: { x: 0, y: 0, z: 0.1, w: 0.1 },
  },
  `
  uniform float iGlobalTime;
  uniform vec2 iResolution;
  uniform vec4 iMouse;

  varying vec2 vUv; 
  void main() {
    vUv = uv;
    vec4 mvPosition = modelViewMatrix * vec4(position, 1.0 );
    gl_Position = projectionMatrix * mvPosition;
  }
`,
  `
    uniform float iGlobalTime;
    uniform vec2 iResolution;
    uniform vec4 iMouse;
    uniform sampler2D iChannel0;
    uniform sampler2D iChannel1;

    #define PASS_COUNT 3

    #define tt iGlobalTime
    #define rr iResolution.xy

    float fBrightness = 2.5;

    // Number of angular segments
    float fSteps = 121.0;

    float fParticleSize = 0.015;
    float fParticleLength = 0.5 / 60.0;

    // Min and Max star position radius. Min must be present to prevent stars too near camera
    float fMinDist = 0.8;
    float fMaxDist = 5.0;

    float fRepeatMin = 1.0;
    float fRepeatMax = 2.0;

    // fog density
    float fDepthFade = 0.6;

    float Random(float x)
    {
    return fract(sin(x * 123.456) * 23.4567 + sin(x * 345.678) * 45.6789 + sin(x * 456.789) * 56.789);
    }

    vec3 GetParticleColour( const in vec3 vParticlePos, const in float fParticleSize, const in vec3 vRayDir )
    {   
    vec2 vNormDir = normalize(vRayDir.xy);
    float d1 = dot(vParticlePos.xy, vNormDir.xy) / length(vRayDir.xy);
    vec3 vClosest2d = vRayDir * d1;
    
    vec3 vClampedPos = vParticlePos;
    
    vClampedPos.z = clamp(vClosest2d.z, vParticlePos.z - fParticleLength, vParticlePos.z + fParticleLength);
    
    float d = dot(vClampedPos, vRayDir);
    
    vec3 vClosestPos = vRayDir * d;
    
    vec3 vDeltaPos = vClampedPos - vClosestPos; 
        
    float fClosestDist = length(vDeltaPos) / fParticleSize;
    
    float fShade =  clamp(.5 - fClosestDist, 0.0, 1.0);
        
    fShade = fShade * exp2(-d * fDepthFade) * fBrightness;
    
    return vec3(fShade);
    }

    vec3 GetParticlePos( const in vec3 vRayDir, const in float fZPos, const in float fSeed )
    {
    float fAngle = atan(vRayDir.x, vRayDir.y);
    float fAngleFraction = fract(fAngle / (3.14 * 2.0));
    
    float fSegment = floor(fAngleFraction * fSteps + fSeed) + 0.5 - fSeed;
    float fParticleAngle = fSegment / fSteps * (3.14 * 2.0);

    float fSegmentPos = fSegment / fSteps;
    float fRadius = fMinDist + Random(fSegmentPos + fSeed) * (fMaxDist - fMinDist);
    
    float tunnelZ = vRayDir.z / length(vRayDir.xy / fRadius);
    
    tunnelZ += fZPos;
    
    float fRepeat = fRepeatMin + Random(fSegmentPos + 0.1 + fSeed) * (fRepeatMax - fRepeatMin);
    
    float fParticleZ = (ceil(tunnelZ / fRepeat) - 0.5) * fRepeat - fZPos;
    
    return vec3( sin(fParticleAngle) * fRadius, cos(fParticleAngle) * fRadius, fParticleZ );
    }

    vec3 Starfield( const in vec3 vRayDir, const in float fZPos, const in float fSeed )
    { 
    vec3 vParticlePos = GetParticlePos(vRayDir, fZPos, fSeed);
    
    return GetParticleColour(vParticlePos, fParticleSize, vRayDir); 
    }

    vec3 RotateX( const in vec3 vPos, const in float fAngle )
    {
        float s = sin(fAngle);
        float c = cos(fAngle);
        
        vec3 vResult = vec3( vPos.x, c * vPos.y + s * vPos.z, -s * vPos.y + c * vPos.z);
        
        return vResult;
    }

    vec3 RotateY( const in vec3 vPos, const in float fAngle )
    {
        float s = sin(fAngle);
        float c = cos(fAngle);
        
        vec3 vResult = vec3( c * vPos.x + s * vPos.z, vPos.y, -s * vPos.x + c * vPos.z);
        
        return vResult;
    }

    vec3 RotateZ( const in vec3 vPos, const in float fAngle )
    {
        float s = sin(fAngle);
        float c = cos(fAngle);
        
        vec3 vResult = vec3( c * vPos.x + s * vPos.y, -s * vPos.x + c * vPos.y, vPos.z);
        
        return vResult;
    }

    mat2 rot(float th) {
        float cs = cos(th), sn = sin(th); return mat2(cs, -sn, sn, cs);
    }


    void main() {

    vec2 vScreenUV = gl_FragCoord.xy / iResolution.xy;
    
    vec2 vScreenPos = vScreenUV * 2.0 - 1.0;

    vScreenPos.y-=.5;
    vScreenPos.x-=.75;


    vec3 vRayDir = normalize(vec3(vScreenPos, 1.0));


    vec3 vEuler = vec3(0.5 + sin(iGlobalTime * 0.1) * 0.125, 0.5 + sin(iGlobalTime * 0.5) * 0.125, iGlobalTime * 0.1 + sin(iGlobalTime * 0.3) * 0.0);
        
    if(iMouse.z > 0.0)
    {
        vEuler.x = -(((iMouse.y )/ iResolution.y) * 2.0 - 1.0);
        vEuler.y = -(((iMouse.x ) / iResolution.x) * 2.0 - 1.0);
        vEuler.z = 0.0;
    }
        
    vRayDir = RotateX(vRayDir, vEuler.x - .3);
    vRayDir = RotateY(vRayDir, vEuler.y);
    vRayDir = RotateZ(vRayDir, vEuler.z);

    
    float fShade = 0.0;
        
    float a = 0.5;
    float b = 3.0;
    float c = 2.0;
    float fZPos = 10.0 + iGlobalTime * c + (iGlobalTime * a) * b;
    float fSpeed = c + a * b * cos(a * iGlobalTime);
    
    fParticleLength = 0.25 * fSpeed / 60.0;
    
    float fSeed = 0.0;
    
    vec3 vResult = mix(vec3(0.005, 0.0, 0.01), vec3(0.01, 0.005, 0.0), vRayDir.y * 0.5 + 0.5);
    
    for(int i=0; i<PASS_COUNT; i++)
    {
        vResult += Starfield(vRayDir, fZPos, fSeed);
        fSeed += 1.234;
    }
    
    

    gl_FragColor = vec4(sqrt(vResult),1.0) ;




        vec2 uv = gl_FragCoord.xy / iResolution.xy;
        uv = uv * 2. -1.;
        vec2 n = texture2D(iChannel1, uv * .1).rg;  // Displacement
        
        vec4 col = texture2D(iChannel0, uv, 1.0) * 0.0;
        
        // Loop through the different inverse sizes of drops
        for (float r = 4. ; r > 0. ; r--) {
            vec2 x = iResolution.xy * r * .035,  // Number of potential drops (in a grid)
                p = 6.28 * uv * x + (n - .5) * 1.,
                s = sin(p);
            
            // Current drop properties. Coordinates are rounded to ensure a
            // consistent value among the fragment of a given drop.
            vec4 d = texture2D(iChannel1, floor((uv * x - 0.25) + 0.5) / x) ;
            
            // Drop shape and fading
            float t = (s.x+s.y) * max(0., 1. - fract(iGlobalTime * (d.b + .1) + d.g) * 2.);;
            
            // d.r -> only x% of drops are kept on, with x depending on the size of drops
            if (d.r < (3.-r)*.08 && t > .5) {
                // Drop normal
                vec3 v = normalize(-vec3(cos(p), mix(.2, 2., t-.5)));
                // fragColor = vec4(v * 0.5 + 0.5, 1.0);  // show normals
                
                // Poor man's refraction (no visual need to do more)
                col = texture2D(iChannel0, uv - v.xy * .3);
            }
        }

        gl_FragColor += col;
        // gl_FragColor = vec4(uv, 0., 1.);

    }
  `
);

extend({ UniverseMaterial });

const U = () => {
  const { viewport } = useThree();

  const material_ref = useRef();

  useFrame(({ clock, mouse }) => {
    material_ref.current.iGlobalTime = clock.elapsedTime * 0.3;
  });

  return (
    <mesh scale={[viewport.width, viewport.height, 0]}>
      <planeGeometry />
      <universeMaterial
        ref={material_ref}
        iResolution={{ x: window.innerWidth, y: window.innerHeight }}
      />
    </mesh>
  );
};

const Universe = () => {
  return (
    <Canvas>
      <U />
    </Canvas>
  );
};

export default Universe;
