import { useEffect } from "react";
import { useSpring, a } from "@react-spring/web";
import Wave from "../../components/wave/wave";
import config from "../../config";
import "./style.scss";
import { useParams } from "react-router-dom";

const image_urls = config.images.ryoshi;

const data = {
  r: {
    img: image_urls.r,
    content: `
        I AM NOT AN ACADEMIC ARCHITECT,\n
        BUT I WHEN I DREW THE BLUEPRINTS I DREW THEM PRECISE.\n
        I AM NOT A GIFTED CARPENTER,\n
        BUT WHEN I LAID THE FLOOR I LAID IT LEVEL.\n
        I AM NOT A NATURAL STONEMASON,\n
        BUT WHEN I MORTARED THE BRICK I MORTARED IT SAFELY.
        `,
  },
  y: {
    img: image_urls.y,
    content: `
        DIVINE ART OF SUBTLETY AND SECRECY.\n
        THROUGH YOU WE LEARN TO BE INVISIBLE, THROUGH YOU INAUDIBLE.\n
        AND HENCE WE CAN HOLD THE ENEMY'S FATE IN OUR HANDS.\n
        `,
  },
  o: {
    img: image_urls.o,
    content: `
        I SEE SKIES OF BLUE, CLOUDS OF WHITE.\n
        THE BRIGHT BLESSED DAY, THE DARK SACRED NIGHT.\n
        AND I THINK TO MYSELF, WHAT A WONDERFUL WORLD.
        `,
  },
  s: {
    img: image_urls.s,
    content: `
        I HEAR BABIES CRY, I WATCH THEM GROW.\n
        THEY'LL LEARN MUCH MORE THAN I'LL EVER KNOW.\n
        AND I THINK TO MYSELF, WHAT A WONDERFUL WORLD.
        `,
  },
  h: {
    img: image_urls.h,
    content: `
        ALL THE COLORS OF THE RAINBOW, SO PRETTY IN THE SKY.\n
        ARE ALSO ON THE FACES OF PEOPLE GOING BY.\n
        I SEE FRIENDS SHAKIN' HANDS SAYIN' "HOW DO YOU DO".\n
        THEY'RE REALLY SAYING "I LOVE YOU".
        `,
  },
  i: {
    img: image_urls.i,
    content: `
        WHEN THE STUDENT IS READY THE MASTER APPEARS. \n
        WHEN THE STUDENT HAS LEARNED ALL THERE IS TO TEACH.\n
        THE MASTER DISAPPEARS.
        `,
  },
};

const RYOSHI = () => {
  const { current_key } = useParams();

  const [styles, api] = useSpring(() => ({
    opacity: 0,
    y: 200,
    config: { mass: 4, tension: 600, friction: 200 },
  }));

  useEffect(() => {
    const audio = new Audio(config.audio.waww);
    audio.loop = true;
    audio.currentTime = 0;
    audio.play();

    api.start({ from: { opacity: 0, y: 200 }, opacity: 1, y: 0 });

    return () => audio.pause();
  }, [api, current_key]);

  return (
    <div className="ryoshi-page">
      <a.div className="card" style={{ ...styles }}>
        <div
          className="content"
          style={{ backgroundImage: `url(${data[current_key].img})` }}
        >
          <div className="mask">
            <div className="text-box">{data[current_key].content}</div>
          </div>
        </div>
      </a.div>
      <Wave />
    </div>
  );
};

export default RYOSHI;
