import { useEffect, useRef } from "react"
import './style.scss'

const MatrixRain = () => {

    const canvas_ref = useRef()

    useEffect(() => {
        const canvas = canvas_ref.current
        const ctx = canvas.getContext('2d') 

        canvas.width = window.innerWidth
        canvas.height = window.innerHeight


        const letters = 'CUT(CTRL-X)'.split('')

        const fontSize = 10
        const matrix_columns = Math.floor(canvas.width / fontSize)

        const drops = Array(matrix_columns).fill(1)
        function draw() {
            ctx.fillStyle = 'rgba(0, 0, 0, .1)'
            ctx.fillRect(0, 0, canvas.width, canvas.height)
            for (let i = 0; i < drops.length; i++) {
                const text = letters[Math.floor(Math.random() * letters.length)]
                ctx.fillStyle = '#5f5';
                ctx.fillText(text, i * fontSize, drops[i] * fontSize)
                drops[i]++;
                if (drops[i] * fontSize > canvas.height && Math.random() > .95) {
                    drops[i] = 0
                }
            }
        }

        const interval = setInterval(draw, 33)
        return () => clearInterval(interval)
    }, [])

    return <canvas ref={canvas_ref}></canvas>
}

export default MatrixRain