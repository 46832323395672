import { useEffect } from 'react'
import logo from '../../asset/images/xx.svg'
import MatrixRain from '../../components/matrixRain/matrixRain'
import config from '../../config'
import './style.scss'

const CtrlX = () => {

    useEffect(() => {
        const audio = new Audio(config.audio.alfa)
        audio.loop = true
        audio.play()

        return () => audio.pause()
    }, [])

    return (
        <div className="ctrl-x-page">
            <div className="page-box">
                <img src={logo} alt='logo' className="logo"  />
                <div className="title">ctrl-x.</div>
                <div className="content">
                    "X" is a variable, also called unknown in elementary mathematics, the value is random, unknown and mysterious.In algebraic operations, variables are substituted into the operations as definite numerical values, and multiple problems can be solved in a single operation.<br />
                    Another great social experiment, the possibilities are endless.<br />
                    NO ONE CAN DEFINE IT, THEN EVERYONE CAN DEFINE IT.
                </div>
                <div className="tips">[ /sp34k ]</div>
            </div>
            <MatrixRain />
        </div>
    )

}

export default CtrlX