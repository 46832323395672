import { useEffect, useMemo, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";
import RYOSHI from "./pages/RYOSHI/RYOSHI";
import CtrlX from "./pages/ctrl_x/ctrl_x";
import DefaultPage from "./pages/default/defalutPage";
import "./style.scss";
import ProjectPage from "./pages/project/project";

function App() {
  const navigate = useNavigate();

  const [state, setState] = useState(null);

  useHotkeys(
    "ctrl+r,ctrl+x, ctrl+h, ctrl+i, ctrl+o, ctrl+s, ctrl+y",
    (e) => {
      e.preventDefault();

      switch (e.key) {
        case "x":
          navigate("/x");
          // setState("x");
          break;
        case "r":
          navigate("/puzzle/r");
          // setState("r");
          break;
        case "h":
          navigate("/puzzle/h");
          // setState("h");
          break;
        case "i":
          navigate("/puzzle/i");
          // setState("i");
          break;
        case "o":
          navigate("/puzzle/o");
          // setState("o");
          break;
        case "s":
          navigate("/puzzle/s");
          // setState("s");
          break;
        case "y":
          navigate("/puzzle/y");
          // setState("y");
          break;
        default:
          setState(null);
      }
    },
    {
      filterPreventDefault: true,
    }
  );

  useEffect(() => {
    const resizeHandler = () => {
      document.documentElement.style.fontSize = `${
        (window.innerWidth / 1920) * 10
      }px`;
    };
    resizeHandler();
    window.addEventListener("resize", resizeHandler);
    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  const currentState = useMemo(() => {
    switch (state) {
      case "x":
        return <CtrlX />;
      case "r":
        return <RYOSHI key={"r"} current_key={state} />;
      case "h":
        return <RYOSHI key={"h"} current_key={state} />;
      case "i":
        return <RYOSHI key={"i"} current_key={state} />;
      case "o":
        return <RYOSHI key={"o"} current_key={state} />;
      case "s":
        return <RYOSHI key={"s"} current_key={state} />;
      case "y":
        return <RYOSHI key={"y"} current_key={state} />;
      default:
        return <DefaultPage />;
    }
  }, [state]);

  return (
    <>
      {/* {currentState} */}
      <Routes>
        {/* <Route path="/" element={currentState} /> */}
        <Route path="/" element={<DefaultPage />} />
        <Route path="/x" element={<CtrlX />} />
        <Route path="/puzzle/:current_key" element={<RYOSHI />} />
        <Route path="/project_description" element={<ProjectPage />} />
      </Routes>
      <div className="pin l t">Brick By Brick</div>
      <div className="pin r t">WE BUILD THIS HOME</div>
      <div className="pin l b">WHILE THE MANOR</div>
      <div className="pin r b">is made elsewhere</div>
    </>
  );
}

export default App;

// const App = () => {
//   const [state, setState] = useState(null);
//   const navigate = useNavigate()

//   useHotkeys(
//     "ctrl+r,ctrl+x, ctrl+h, ctrl+i, ctrl+o, ctrl+s, ctrl+y",
//     (e) => {
//       e.preventDefault();
//       switch (e.key) {
//         case "x":
//           navigate('/x')
//           break;
//         case "r":
//           navigate('/r')
//           break;
//         case "h":
//           navigate('/h')
//           break;
//         case "i":
//           navigate('/i')
//           break;
//         case "o":
//           navigate('/o')
//           break;
//         case "s":
//           navigate('/s')
//           break;
//         case "y":
//           navigate('/y')
//           break;
//         default:
//           setState(null);
//       }
//     },
//     {
//       filterPreventDefault: true,
//     }
//   );

//   return (
//     <div>
//         <Routes>
//           <Route path="/" element={<DefaultPage />} />
//           <Route path="/x" element={<CtrlX />} />
//           <Route path="/r" element={<RYOSHI key={"r"} current_key={"r"} />} />
//           <Route path="/h" element={<RYOSHI key={"h"} current_key={"h"} />} />
//           <Route path="/i" element={<RYOSHI key={"i"} current_key={"i"} />} />
//           <Route path="/o" element={<RYOSHI key={"o"} current_key={"o"} />} />
//           <Route path="/s" element={<RYOSHI key={"s"} current_key={"s"} />} />
//           <Route path="/y" element={<RYOSHI key={"y"} current_key={"y"} />} />
//         </Routes>
//       <div className="pin l t">Brick By Brick</div>
//       <div className="pin r t">WE BUILD THIS HOME</div>
//       <div className="pin l b">WHILE THE MANOR</div>
//       <div className="pin r b">is made elsewhere</div>
//     </div>
//   );
// };

// export default App;
