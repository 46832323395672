import { useMemo } from 'react'
import * as THREE from 'three'
import { Canvas, useFrame } from '@react-three/fiber'
import useMouse from '../../hooks/useMouse'

const Particle = () => {

    return (
        <Canvas camera={{ fov: 75, near: 1, far: 10000 }}>
            <color attach='background' args={['black']} />
            <fog attach="fog" color="black" near={100} far={1000} />
            <P />
        </Canvas>
    )
}


const P = () => {

    const mouse = useMouse()

    const p = useMemo(() => {

        const parameters = { count: 100 }
        const geometry = new THREE.BufferGeometry()
        const positions = new Float32Array(parameters.count * 3)

        for (let i = 0; i < parameters.count; i++) {

            const x = (Math.random() - .5) * 2
            const y = (Math.random() - .5) * 2
            const z = (Math.random() - .5) * 2

            const v = (new THREE.Vector3(x, y, z)).normalize().multiplyScalar(Math.random() * 10 + 450)

            const i3 = i * 3

            positions[i3] = v.x
            positions[i3 + 1] = v.y
            positions[i3 + 2] = v.z


        }

        geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3, true))

        return geometry

    }, [])

    useFrame(({ camera, scene }) => {
        camera.position.x += (mouse.x - camera.position.x) * .05
        camera.position.y += (-mouse.y + 200 - camera.position.y) * .05
        camera.position.z = 100
        camera.lookAt(scene.position)
    })



    return (
        <object3D >
            <points>
                <primitive object={p} attach='geometry' />
                <pointsMaterial size={10} transparent onBeforeCompile={(shader) => {
                    shader.fragmentShader = shader.fragmentShader.replace(
                        '#include <premultiplied_alpha_fragment>',
                        `
                            #include <premultiplied_alpha_fragment>
                            if(length(gl_PointCoord - vec2(.5)) < .5) {
                                gl_FragColor.a = 1.;
                            } else {
                                gl_FragColor.a = 0.;
                            }
                            
                        `)
                }} />
            </points>
            <line>
                <primitive object={p} attach='geometry' />
                <lineBasicMaterial linewidth={10} opacity={0.4} transparent />
            </line>
        </object3D>
    )
}

export default Particle

