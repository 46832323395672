const config = {
  images: {
    logo: "https://ctrl-x-storage-a6e56c5052131-staging.s3.ap-northeast-1.amazonaws.com/images/logo.png",
    ryoshi: {
      r: "https://ctrl-x-storage-a6e56c5052131-staging.s3.ap-northeast-1.amazonaws.com/images/r.png",
      y: "https://ctrl-x-storage-a6e56c5052131-staging.s3.ap-northeast-1.amazonaws.com/images/y.png",
      o: "https://ctrl-x-storage-a6e56c5052131-staging.s3.ap-northeast-1.amazonaws.com/images/o.png",
      s: "https://ctrl-x-storage-a6e56c5052131-staging.s3.ap-northeast-1.amazonaws.com/images/s.png",
      h: "https://ctrl-x-storage-a6e56c5052131-staging.s3.ap-northeast-1.amazonaws.com/images/h.png",
      i: "https://ctrl-x-storage-a6e56c5052131-staging.s3.ap-northeast-1.amazonaws.com/images/i.png",
    },
    roadmap: {
      map1: 'https://ctrl-x-storage-a6e56c5052131-staging.s3.ap-northeast-1.amazonaws.com/images/1.png',
      map2: 'https://ctrl-x-storage-a6e56c5052131-staging.s3.ap-northeast-1.amazonaws.com/images/2.png',
      map3: 'https://ctrl-x-storage-a6e56c5052131-staging.s3.ap-northeast-1.amazonaws.com/images/3.png',
      map4: 'https://ctrl-x-storage-a6e56c5052131-staging.s3.ap-northeast-1.amazonaws.com/images/4.png',
      map5: 'https://ctrl-x-storage-a6e56c5052131-staging.s3.ap-northeast-1.amazonaws.com/images/5.png',
      map6: 'https://ctrl-x-storage-a6e56c5052131-staging.s3.ap-northeast-1.amazonaws.com/images/6.png',
      map7: 'https://ctrl-x-storage-a6e56c5052131-staging.s3.ap-northeast-1.amazonaws.com/images/7.png',
      map8: 'https://ctrl-x-storage-a6e56c5052131-staging.s3.ap-northeast-1.amazonaws.com/images/8.png',
    }
  },
  audio: {
    alfa: "https://ctrl-x-storage-a6e56c5052131-staging.s3.ap-northeast-1.amazonaws.com/audio/alfa.mp3",
    waww: "https://ctrl-x-storage-a6e56c5052131-staging.s3.ap-northeast-1.amazonaws.com/audio/waww.mp3",
  },
};

export default config;
