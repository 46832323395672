import RoadmapBox from "../../components/roadmapBox/roadmapbox";
import Universe from "../../components/universe/universe";
import { useNavigate } from "react-router-dom";
import "./style.scss";

const data = {
  introduce: `
    CTRL-X ($CUT) was created by a mysterious organization called "Cut" that continues to deliver value to the decentralized crypto community by cutting and absorbing dark energy.
    $CUT is the first token in the CRTL-X ecosystem. This project will explore the boundaries of web 3.0 business in addition to building a wider application of decentralized financial business.
    As a decentralized project pursuing the ultimate in security, CUT has taken the lead in fair launch; the ecosystem will be revealed gradually as time passes.`,
  aboutCut: {
    title: "Introduction to Cut",
    content: `Ctrl-X (cut) is not a traditional meme token, but a series of experiments with a higher latitude being built by a decentralized community. It is aimed at the giants that the market has proven to be. As the name Ctrl-X stands for dominance, each action will bring something new to Cut, expanding and enriching its ecosystem, and ensuring its immense viability.`,
  },
};

const ProjectPage = () => {
  const navigate = useNavigate();

  return (
    <div className="project-box">
      <div className="bg">
        <div className="mask">
          <div className="indroduce">{data.introduce}</div>
          <div className="about-cut">
            <div className="title">{data.aboutCut.title}</div>
            <div className="content">{data.aboutCut.content}</div>
          </div>
          <div className="roadmap">
            <div className="title">Roadmap</div>
          </div>
          <RoadmapBox />
            <div className="ground">
            </div>
          <div className="go-back" onClick={() => navigate('/')}>back to the home</div>
        </div>
        <Universe />
      </div>
    </div>
  );
};

export default ProjectPage;
