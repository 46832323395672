import { useEffect, useState } from "react"

const useMouse = () => {

    const [mouse, setMouse] = useState({ x: 0, y: 0 })

    useEffect(() => {
        const mouseMoveHandler = (e) => {
            const x = e.x - window.innerWidth / 2
            const y = e.y - window.innerHeight / 2
            setMouse({ x, y })
        }

        window.addEventListener('mousemove', mouseMoveHandler)

        return () => window.removeEventListener('mousemove', mouseMoveHandler)
    }, [])

    return mouse

}

export default useMouse