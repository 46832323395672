import {useNavigate} from 'react-router-dom'
import Particle from '../../components/particle/particle'
import medium from '../../asset/images/icon/medium.png'
import whatsapp from '../../asset/images/icon/whatsapp.png'
import twitter from '../../asset/images/icon/twitter.png'
import './style.scss'

const DefaultPage = () => {

    const navigate = useNavigate()

    return (
        <div className="default-page">
            <div className="content-box">
                <div className="title">ctrl-x.</div>
                <p className="buttons">
                    <a onClick={() => navigate('/project_description') } >project description</a>
                    |
                    <a href="https://etherscan.io/token/0x135783b60cf5d71daff7a377f9eb7db8d2deab9e" className="button" >contract</a>
                    |
                    <a href="https://app.uniswap.org/#/swap?outputCurrency=0x135783b60cf5d71daff7a377f9eb7db8d2deab9e" className="button">buy</a>
                </p>
                <div className="second">try combinations, uncover the fog</div>
                <div className="icon-box">
                    <a className="icon" href="https://shewhobuilt.medium.com/an-entry-to-the-first-entry-of-our-story-together-95553e790132">
                        <img src={medium} alt="medium" />
                    </a>
                    <a className="icon" href="https://t.me/Ctrlx_Token">
                        <img src={whatsapp} alt="whatsapp" />
                    </a>
                    <a className="icon" href="https://twitter.com/CtrlX_Token" >
                        <img src={twitter} alt="twitter" />
                    </a>
                </div>
                <div className="tips">maybe try cutting</div>
            </div>
            <Particle />
        </div>
    )
}

export default DefaultPage